import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Container from "../block/container"
import FieldText from "../field/fieldText"
import "../../styles/components/block--try.scss"

const BlockTry = () => {
  return (
    <Container classes="block--try">
      <FieldText>
        <h2>Ready to step into the future of team work?</h2>
        <p>
          Sideline helps creative teams and agencies easily manage inbound
          project requests. Ready to boost your team's productivity?
        </p>
        <Link to={"#"} className="button button--big button--red">
          Schedule a Demo
        </Link>
        <Link
          to={"#"}
          className="button button--big button--transparent button--try"
        >
          Try It For Free for 14 Days!
        </Link>
      </FieldText>
      <StaticImage
        as="figure"
        className="field field--image"
        src="../../assets/images/schedule-demo.jpg"
        alt="Sideline application"
        title="Sideline application"
      />
    </Container>
  )
}

export default BlockTry

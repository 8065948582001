import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const ImageButton = ({ children, link, linkTitle }) => (
  <div className="image-button">
    {children}
    <Link to={link} className="button button--red button--medium">
      {linkTitle}
    </Link>
  </div>
)

ImageButton.propTypes = {
  link: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ImageButton

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/regions/header"
import Container from "../components/block/container"
import FieldText from "../components/field/fieldText"
import ContactInfo from "../components/item/contactInfo"
import ImageButton from "../components/item/imageButton"
import BlockTry from "../components/full/blockTry"
import "../styles/components/block--page-top-content--contact.scss"
import "../styles/components/block--contact-options.scss"
import "../styles/components/block--contact-info--short.scss"

const ContactPage = () => (
  <>
    <Header />
    <Layout>
      <Seo
        title="Contact Us"
        description="You have got questions? We've got answers! Learn more about our best-in-class workflows, APIs, email blueprints, and more."
        pageClass="page--contact"
      />

      <Container classes="block--page-top-content block--page-top-content--contact">
        <FieldText>
          <h1 className="text text--centered">Contact Us</h1>
          <p className="text text--centered">
            You have got questions? We've got answers! Learn more about our
            best-in-class workflows, APIs, email blueprints, and more.
          </p>
        </FieldText>
      </Container>

      <Container classes="block--contact-options">
        <ImageButton linkTitle="Request a Demo" link="#">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/contact--request-demo.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="Request a Demo"
          />
        </ImageButton>

        <ImageButton linkTitle="Book a Call" link="#">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/contact--book-call.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="Book a Call"
          />
        </ImageButton>

        <ImageButton linkTitle="Customer Support" link="#">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/contact--customer-support.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="Customer Support"
          />
        </ImageButton>
      </Container>

      <Container classes="block--contact-info block--contact-info--short">
        <ContactInfo mail="hello@sideline.io" label="General contact:">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/contact--general-contact.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="General contact"
          />
        </ContactInfo>

        <ContactInfo mail="partners@sideline.io" label="Partnership inquiries:">
          <StaticImage
            as="figure"
            className="field field--image"
            src="../assets/images/contact--partnership-inquiries.png"
            layout="fixed"
            quality="90"
            placeholder="blurred"
            alt="Partnership inquiries"
          />
        </ContactInfo>
      </Container>

      <BlockTry />
    </Layout>
  </>
)

export default ContactPage
